.imgModal {
    background: transparent;
    border:none !important;
  }
  
  .imgModal > * {
    background: transparent;
    border:none !important;
  }

  #\:r7\: > div > div{
    width: 100% !important;
    height: 100% !important;
  }